
.lawyer-item {
    display: block;
    width: 100%;
    &__tit {
        border-top: 1px solid var(--border-color);
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            top: -1px;
            left: 0;
            background-color: var(--v-primary-lighten3);
            transform: scaleX(0);
            transition: 0.25s ease-out;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    // .lawyer-item:hover {
    //     .lawyer-item__tit {
    //         &::after {
    //             transform: scaleX(1);
    //         }
    //     }
    // }
}
@media (min-width: 1200px) {
}
