
.page-section{
    &--about{
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        &__bg{
            position: absolute;
            bottom: var(--contents-padding-bottom);
            left: 0;
            width: 36%;
            z-index: -1;
            display: none;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .page-section{
        &--about{
            &__bg{
                display: block;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

