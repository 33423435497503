
.image-row{
    --image-padding: 20px;
    &__image{
        &__inner{
            max-width: 588px;
            position: relative;
            padding: 0 0 var(--image-padding) var(--image-padding);
        }
        &__bg{
            position: absolute;
            width: calc(100% - var(--image-padding));
            height: calc(100% - var(--image-padding));
            left: 0;
            bottom: 0;
            background-color: var(--v-primary-lighten5);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .image-row{
        --image-padding: 48px;
        &__image{
            padding-right: 30px;
        }
        &__txt{
            padding-left: calc(var(--image-padding) + 6px) !important;
            padding-right: var(--image-padding);
        }
        &--reverse{
            flex-direction: row-reverse;
            .image-row__txt{
                padding-left: var(--image-padding) !important;
                padding-right: calc(var(--image-padding) + 6px) !important;
            }
            .image-row__image{
                padding-right: 0;
                padding-left: 30px;
                &__inner{
                    padding: var(--image-padding) var(--image-padding) 0 0;
                }
                &__bg{
                    left: unset;
                    bottom: unset;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
